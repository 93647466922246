.scc__connect__header {
  font-size: 2em;
  margin-bottom: 1.5em;
  letter-spacing: 0.15em;
  font-weight: 900;
  color: white;
  text-align: center;
}

.scc__connect__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

div > .scc__content__container:nth-of-type(2n) {
  flex-direction: row-reverse;
}

.scc__content__container {
  display: flex;
  align-items: center;
  color: white;
  text-align: center;
}

.scc__connect__img {
  height: 400px;
  border-radius: 10px;
  margin-inline: 2em;
  transition: 0.5s ease-in-out;
}

.scc__connect__img:hover,
.scc__connect__img:focus {
  transform: scale(1.05);
}

.scc__connect__text {
  margin-inline: 2em;
}

@media screen and (max-width: 1000px) {
  div > .scc__content__container:nth-of-type(2n) {
    flex-direction: column;
  }

  .scc__content__container {
    flex-direction: column;
    margin: 2rem;
  }
  .scc__connect__img {
    height: 40vh;
    margin: none;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .scc__connect__header {
    font-size: 1.5rem;
    margin-bottom: 0.5em;
  }

  div > .scc__content__container:nth-of-type(2n) {
    flex-direction: column;
  }

  .scc__content__container {
    flex-direction: column;
    margin: .15em;
    font-size: 1rem;
  }
  .scc__connect__img {
    height: 25vh;
    margin: none;
  }
}
