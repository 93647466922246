.scc__thankyou__img {
    width: 100%;
    align-self: center;
  }

  .scc__thankyou__container {
    color: white;
    font-weight: 900;
    text-align: center;
    font-size: 1.5rem;
    margin: 6rem 2rem;
  }