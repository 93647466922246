.scc__vbs__container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scc__vbs__form {
  width: 100vw;
  height: 100vh;
}
