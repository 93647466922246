.scc__about__img {
  width: 100%;
  position: absolute;
  opacity: 0.5;
  z-index: -1;
  align-self: center;
  margin-bottom: 3rem;
}

.scc__about__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scc__about__paragraph {
  margin: 1em;
  color: white;
  text-align: center;
  width: 80%;
  font-weight: 300;
}

.scc__paragraph__heading {
  font-size: 1.5rem;
  font-weight: 900;
}

@media screen and (max-width: 575px) {
  .scc__about__paragraph {
    margin: .15em;
    font-size: 1em;
  }

  .scc__paragraph__heading {
    font-size: 1.5rem;
    font-weight: 900;
  }
}
