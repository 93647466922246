.scc__error__container {
  height: 75vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scc__error__fourofour,
.scc__error__header {
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  margin-inline: 0.25em;
}

.scc__error__fourofour {
  font-size: 3.5em;
}

.scc__error__header {
  font-size: 1.5em;
  margin-bottom: 0.75em;
}

.scc__error__link {
  cursor: pointer;
  position: relative;
  background: none;
  padding: 0.5rem 1.75rem;
  border: 4px solid white;
  border-radius: 3px;
  font-weight: 700;
  font-size: 1em;
  letter-spacing: 0.15rem;
  color: white;
  text-decoration: none;
  transition: 0.7s;
}

.scc__error__link:hover,
.scc__error__link:focus {
  transform: translateY(-0.25em);
}
