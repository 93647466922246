.scc__home__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.scc__head__img {
  width: 100vw;
  align-self: center;
  margin-bottom: 1em;
}

.scc__text__container {
  margin-bottom: 1.5em;
  text-align: center;
  font-size: 1em;
}

.scc__text__container > p {
  margin: 0.25em;
}

.scc__text__span {
  margin-inline: 0.25rem;
  font-size: 2rem;
}

.scc__address {
  margin-inline: 0.25rem;
  color: var(--scc-white);
  transition: 0.5s;
}

.scc__address:hover,
.scc__address:focus {
  color: rgb(202, 202, 202);
}

.scc__socials__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.scc__socials {
  color: white;
  transition: 0.5s;
}

.scc__socials:hover,
.scc__socials:focus {
  transform: translateY(-0.25em);
}

.scc__video__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.scc__latest__vid {
  border-radius: 10px;
  margin: 0.5rem;
  width: 600px;
  height: 340px;
  transition: 0.5s;
}

.scc__latest__vid:hover,
.scc__latest__vid:focus {
  transform: translateY(-0.25em);
}

@media screen and (max-width: 575px) {
  .scc__text__container {
    font-size: 0.9em;
    text-align: center;
  }

  .scc__text__container > p {
    margin: 0.5em;
  }

  .scc__head__img {
    width: 100vw;
    align-self: center;
    margin-bottom: 2em;
  }

  .scc__text__span {
    margin-inline: 0.25rem;
    font-size: 1.5rem;
  }

  .scc__socials__container {
    gap: 1.5rem;
    font-size: 2.5rem;
    margin: 0.5em;
  }

  .scc__latest__vid {
    width: auto;
    height: auto;
  }
}

@media screen and (max-width: 375px) {
  .scc__text__container {
    margin-bottom: 0em;
    margin-inline: 0.5em;
    font-size: 1em;
    text-align: center;
  }

  .scc__text__span {
    margin-inline: 0.25rem;
    font-size: 1.15em;
  }

  .scc__socials__container {
    gap: 1.5rem;
    font-size: 2em;
    margin: 0.5em;
  }

  .scc__latest__vid {
    width: auto;
    height: auto;
  }
}
