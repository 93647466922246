.scc__nav__container {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scc__img {
  height: 7vh;
  transition: 0.6s;
}

.scc__img:hover,
.scc__img:focus {
  transform: translateY(-0.25em);
}

.scc__nav__end {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.scc__give__button {
  cursor: pointer;
  position: relative;
  background: none;
  padding: 0.5rem 1.75rem;
  border: 4px solid white;
  border-radius: 3px;
  font-weight: 700;
  font-size: 1em;
  letter-spacing: 0.15rem;
  color: white;
  text-decoration: none;
  transition: 0.7s;
}

.scc__give__button:hover,
.scc__give__button:focus {
  transform: translateY(-0.25em);
}

.scc__nav__button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.7rem;
  color: white;
  transition: 0.5s;
}

.scc__nav__button:hover,
.scc__nav__button:focus {
  transform: translateY(-0.15em);
}

.scc__side__nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #262626;
  overflow-x: hidden;
  padding-top: 2rem;
  transition: 0.6s;
}

.scc__side__nav__show {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  width: 25%;
}

.scc__nav__x {
  margin: 0 1rem;
  padding-bottom: 3rem;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  transition: 0.5s;
}

.scc__nav__x:hover,
.scc__nav__x:focus {
  transform: translateY(-0.15em);
}

.scc__nav__new__hidden {
  background-color: #262626;
  color: #262626;
  border: none;
}

.scc__nav__new__show {
  margin-inline: auto;
  cursor: pointer;
  background: white;
  padding: 0.5rem 1.75rem;
  margin-bottom: 2.5em;
  border: 4px solid white;
  border-radius: 3px;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  text-decoration: none;
  transition: 0.5s;
  color: #262626;
}

.scc__nav__new__show:hover,
.scc__nav__new__show:focus {
  transform: translateY(-0.25em);
}

.scc__nav__link {
  cursor: pointer;
  margin-inline: auto;
  color: transparent;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2.5em;
  transition: transform 0.5s;
}

.scc__nav__link__hidden {
  visibility: hidden;
}

.scc__nav__link__show {
  visibility: visible;
  color: white;
}

.scc__nav__link__show:hover,
.scc__nav__link__show:focus {
  transform: translateY(-0.25em);
}

@media screen and (max-width: 800px) {
  .scc__img {
    height: 7vh;
  }

  .scc__nav__end {
    gap: 1rem;
  }

  .scc__give__button {
    border: 3px solid white;
    font-size: 0.7rem;
    text-decoration: none;
    padding: 0.45rem 1.5rem;
  }

  .scc__nav__button {
    font-size: 1.35rem;
  }

  .scc__side__nav {
    padding-top: 1.5rem;
  }

  .scc__side__nav__show {
    height: 100%;
    width: 35%;
  }

  .scc__nav__x {
    margin: 0.8rem 1rem;
    font-size: 1.25rem;
  }

  .scc__nav__new__show {
    padding: 0.45rem 1.5rem;
    margin-bottom: 3.5em;
    border: 3px solid white;
    font-size: 0.7rem;
    text-decoration: none;
  }

  .scc__nav__link {
    font-size: 1rem;
    margin-bottom: 3.5em;
  }
}

@media screen and (max-width: 575px) {
  .scc__img {
    height: 6vh;
  }

  .scc__nav__end {
    gap: 0.5rem;
  }

  .scc__give__button {
    border: 3px solid white;
    font-size: 1em;
    text-decoration: none;
    padding: 0.35rem 1.35rem;
  }

  .scc__nav__button {
    font-size: 1.25em;
  }

  .scc__side__nav {
    padding-top: 1.5rem;
  }

  .scc__side__nav__show {
    height: 100%;
    width: 100%;
  }

  .scc__nav__x {
    margin: 0.5rem 1rem;
    font-size: 1.25rem;
  }

  .scc__nav__new__show {
    padding: 0.35rem 1.35rem;
    border: 3px solid white;
    margin-bottom: 2em;
    font-size: 1em;
    text-decoration: none;
  }

  .scc__nav__link {
    font-size: 1.25em;
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 375px) {
  .scc__nav__x {
    margin: 0 1rem;
    font-size: 1.25rem;
  }
}