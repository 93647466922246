.scc__events__img {
  width: 100%;
  align-self: center;
}

.scc__embed__social__container {
  height: 35rem;
}

.scc__embed__social {
  height: 100%;
  width: 100%;
  border: none;
}
