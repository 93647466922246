.scc__new__header {
  font-size: 2em;
  margin-bottom: 1em;
  font-weight: 900;
  letter-spacing: 0.15em;
  color: white;
  text-align: center;
}

.scc__form__container {
  margin-inline: auto;
  width: 70%;
  margin-bottom: 3rem;
}

.scc__form {
  display: flex;
  flex-direction: column;
}

.scc__form > label {
  color: white;
  font-weight: 700;
  letter-spacing: 0.15em;
  font-size: 1.15em;
  text-transform: uppercase;
  padding-block: 0.5em;
}

.scc__form > input {
  font-family: inherit;
  font-weight: 500;
  padding: 0.75rem;
  margin-bottom: 0.75em;
  border-radius: 5px;
  border: 2px solid white;
  color: white;
  background-color: transparent;
}

.scc__form > textarea {
  resize: none;
  font-family: inherit;
  font-weight: 500;
  padding-bottom: 5rem;
  margin-bottom: 0.75em;
  border-radius: 5px;
  border: 2px solid white;
  color: white;
  background-color: transparent;
}

.scc__form > select {
  width: 50%;
  padding: 0.75em;
  text-align: center;
  font-family: inherit;
  text-transform: uppercase;
  font-weight: 700;
  margin-inline: auto;
  border-radius: 5px;
  border: 2px solid white;
  color: white;
  background-color: transparent;
}

.scc__form__ministries {
  text-align: center;
}

.scc__form__button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5em;
  gap: 1.5em;
}

.scc__form__button {
  cursor: pointer;
  background: none;
  padding: 0.5rem 1.75rem;
  border: 3.5px solid white;
  border-radius: 3px;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  color: white;
  text-decoration: none;
  transition: 0.7s;
}

.scc__form__button:hover,
.scc__form__button:focus {
  transform: translateY(-0.25em);
  color: #262626;
  background-color: white;
}

.scc__form > input:focus-visible,
.scc__form > input:focus,
.scc__form > textarea:focus-visible,
.scc__form > textarea:focus {
  outline: 5px solid var(--scc-navy);
}

@media screen and (max-width: 700px) {
  .scc__new__header {
    font-size: 1.25rem;
  }

  .scc__form__container {
    width: 85%;
  }

  .scc__form > label {
    font-size: 0.75em;
  }

  .scc__form > input {
    padding: 0.5rem;
    border: 2px solid white;
  }

  .scc__form > textarea {
    padding-bottom: 3.5rem;
    margin-bottom: 0.75em;
    border: 2px solid white;
  }

  .scc__form > select {
    width: 80%;
    border: 2px solid white;
  }

  .scc__form__button {
    cursor: pointer;
    padding: 0.5rem 1.75rem;
    border: 2.5px solid white;
    font-size: 0.75rem;
  }
}
