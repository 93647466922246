.scc__vbs__container {
  margin-block: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.scc__vbs__text__container {
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scc__rocky__railroad__logo {
  max-width: 25%;
  height: auto;
}

.scc__vbs__heading {
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0;
}

.scc__vbs__subheading {
  margin-block: 0.5em;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 300;
}

.scc__vbs__countdown__text__container {
  margin: 1em;
  text-align: center;
}

.scc__vbs__countdown__container {
  margin-top: 1em;
}

.scc__vbs__countdown {
  font-size: 2em;
  font-weight: 300;
}

.scc__vbs__countdown__number,
.scc__vbs__subheading > strong,
.scc__vbs__link {
  color: #fffaa0;
}

.scc__vbs__link {
  margin-bottom: 1.5em;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  transition: 0.7s;
}

.scc__vbs__button {
  cursor: pointer;
  position: relative;
  background: none;
  padding: 0.5rem 1.75rem;
  border: 4px solid white;
  border-radius: 3px;
  font-weight: 700;
  font-size: 1em;
  letter-spacing: 0.15rem;
  color: white;
  text-decoration: none;
  transition: 0.7s;
}

.scc__vbs__button:hover,
.scc__vbs__button:focus,
.scc__vbs__link:hover,
.scc__vbs__link:focus {
  transform: translateY(-0.25em);
}

@media screen and (max-width: 900px) {
  .scc__rocky__railroad__logo {
    max-width: 50%;
  }
}

@media screen and (max-width: 575px) {
  .scc__rocky__railroad__logo {
    max-width: 60%;
  }

  .scc__vbs__heading {
    font-size: 1.25rem;
    font-weight: 900;
  }

  .scc__vbs__subheading {
    font-size: 1em;
  }

  .scc__vbs__countdown {
    font-size: 1.25em;
  }

  .scc__vbs__button {
    border: 3px solid white;
    font-size: 1em;
    text-decoration: none;
    padding: 0.35rem 1.35rem;
  }
}
