.scc__poweredup__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scc__poweredup__form {
    width: 100vw;
    height: 100vh;
}