.scc__footer {
  position: absolute;
  font-size: 0.8em;
  color: var(--scc-light-gray);
  bottom: 1em;
  text-align: center;
  width: 100%;
}

.scc__footer__title {
  font-weight: 900;
  margin: 0.5em;
}

.scc__footer__link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: 0.5s;
}

.scc__footer__link:focus,
.scc__footer__link:hover {
  color: white;
}

@media screen and (max-width: 575px) {
  .scc__footer {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 375px) {
  .scc__footer {
    font-size: 0.6em;
  }
}
