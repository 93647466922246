.scc__pastors__container {
  height: 100%;
  display: flex;
  margin-bottom: 1rem;
}

.scc__pastors__img {
  margin: 2.5em;
  height: 400px;
  border-radius: 10px;
  position: sticky;
  top: 2.5em;
}

.scc__pastors__text__container {
  width: 50%;
  margin: 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
}

.scc__pastors__text__header {
  font-size: 1.5rem;
  font-weight: 900;
}

@media screen and (max-width: 1000px) {
  .scc__pastors__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .scc__pastors__img {
    margin: 2.5em;
    height: auto;
    position: static;
    border-radius: 10px;
  }

  .scc__pastors__text__container {
    width: auto;
    margin: 1.5em;
    font-size: 0.8rem;
  }

  .scc__pastors__text__header {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 575px) {
  .scc__pastors__img {
    width: 100%;
    height: auto;
    margin: 0;
    margin-inline: auto;
    border-radius: 0px;
  }

  .scc__pastors__text__container {
    width: auto;
    margin: 1.5em;
    font-size: 1em;
  }
}
