:root {
  --scc-dark-gray: #1c1c1c;
  --scc-mid-gray: #262626;
  --scc-light-gray: #5e5e5e;
  --scc-navy: #003663;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 5em;
  font-family: "Lato", sans-serif;
  background-color: var(--scc-dark-gray);
}
